import { Theme, UnbrandedEducationTheme } from "@modernatx/ui-kit-react";

const accent01 = "#CA3256";
const buttonBackgroundColor = "#CA3256";
const buttonColorPrimary = "#FFF";
const iconButtonBackgroundColor = "#F3D5DC";
const iconButtonHoverBackgroundColor = "#eda3b5";
const iconButtonActiveBackgroundColor = "#CA3256";
const buttonHoverBackgroundColorPrimary = "#921b37";
const buttonActiveBackgroundColorPrimary = "#7a132b";
const buttonBorderColorPrimary = "#FFF";

const buttonStyles: Partial<Theme["colorsLight"]> = {
  buttonActiveBorderColorSecondary: accent01,
  buttonBackgroundColorPrimary: buttonBackgroundColor,
  buttonColorPrimary: buttonColorPrimary,
  buttonHoverBorderColorSecondary: accent01,
  iconButtonBackgroundColor: iconButtonBackgroundColor,
  iconButtonHoverBackgroundColor: iconButtonHoverBackgroundColor,
  iconButtonActiveBackgroundColor: iconButtonActiveBackgroundColor,
  cta01: accent01
};

const iconButtonStyles: Partial<Theme["colorsDark"]> = {
  buttonActiveBackgroundColorPrimary: buttonActiveBackgroundColorPrimary,
  buttonBackgroundColorPrimary: accent01,
  buttonBorderColorPrimary: buttonBorderColorPrimary,
  buttonColorPrimary: buttonColorPrimary,
  buttonHoverBackgroundColorPrimary: buttonHoverBackgroundColorPrimary
};

export const JPCovidTheme: Theme = {
  ...UnbrandedEducationTheme,
  fonts: {
    ...UnbrandedEducationTheme.fonts,
    body: "NotoSansJP",
    headlineLevel1: "NotoSansJP"
  },
  colorsLight: {
    ...UnbrandedEducationTheme.colorsLight,
    ...buttonStyles,
    accent01: accent01
  },
  colorsDark: {
    ...UnbrandedEducationTheme.colorsDark,
    ...iconButtonStyles,
    accent01: accent01
  }
};
